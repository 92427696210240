<template>
  <div class="main-container">
    <div class="main-heading">
      <h1>{{$t('DAILY_DEALS')}}</h1>
    </div>
    <div class="container">
        <div v-for="(arena, index) in shopObj.content" v-bind:key="index">
            <div class="arena-item" @click="() => toggleSection(index)">
                <p>{{ index == shopObj.content.length - 1 ? "Leagues" : ("Arena " + (index + 1)) }}</p>
            </div>
            <div class="arena-content" v-if="isSectionOpen[index]">
                <div v-for="(pool, poolIndex) in Object.keys(shopObj.content[index]).sort((a, b) => a - b).map(x => shopObj.content[index][x])" v-bind:key="poolIndex">
                    <div class="col">
                        <h4 style="text-align: start;">{{ "Pool " + poolIndex }}</h4>
                        <div class="action-buttons-table">
                            <div class="row header-row">
                                <p>Type</p>
                                <p>Rarity</p>
                                <p>Chest Type</p>
                                <p>Amount</p>
                                <p>Currency</p>
                                <p>Price</p>
                            </div>
                            <div v-for="(reward, rewardIndex) in pool" v-bind:key="rewardIndex" class="row">
                                <select v-model.number="pool[rewardIndex].reward.type" @change="initializeRewardType(pool[rewardIndex].reward)">
                                    <option v-for="option in shopConstants.REWARD_TYPES" :value="option.value" v-bind:key="option.value">{{ option.text }}</option>
                                </select>
                                <select :disabled="[0, 1, 4].includes(pool[rewardIndex].reward.type)" v-model.number="pool[rewardIndex].reward.rarity">
                                    <option v-for="option in shopConstants.CARD_RARITY" :value="option.value" v-bind:key="option.value">{{ option.text }}</option>
                                </select>
                                <select :disabled="pool[rewardIndex].reward.type !== 4" v-model="pool[rewardIndex].reward.chest_id" >
                                  <option v-for="option in chests" :value="option.id" v-bind:key="option.id">{{ option.name }}</option>
                                </select>
                                <input v-model.number="pool[rewardIndex].reward.amount" />
                                <select :disabled="rewardIndex === 0" v-model="Object.keys(pool[rewardIndex].price)[0]" @change="(event) => onCurrencyChange(event.target.value, pool[rewardIndex].price)">
                                    <option v-for="option in shopConstants.CURRENCY_TYPES" :value="option.value" v-bind:key="option.value">{{ option.text }}</option>
                                </select>
                                <input :disabled="rewardIndex === 0" v-model.number="pool[rewardIndex].price[Object.keys(pool[rewardIndex].price)[0]]" />
                            </div>
                        </div>
                        <button class="error" @click="() => removePool(index, poolIndex + 1)">Remove Pool</button>
                    </div>
                </div>
                <button class="success" @click="() => addPool(index)">Add New Pool</button>
            </div>
        </div>
    </div>
    
    <div class="buttons-section">
      <button class="success" @click="openConfirm">Save</button>
      <button v-if="env === 'staging' || env === 'prod'" class="info" @click="() => exportTo('dev')">Export to Development</button>
      <button v-if="env === 'dev' || env === 'prod'" class="info" @click="() => exportTo('staging')">Export to Staging</button>
      <button v-if="env === 'dev' || env === 'staging'" class="info" @click="() => exportTo('prod')">Export to Production</button>
    </div>
        <ConfirmPopup :popup-open="popupOpen" :text="popupText" @popup-close="popupOpen=false" :type="popupType" :changes="changes" @confirm="submit"/>
  </div>
</template>

<script>
import { watch, ref, computed, onBeforeMount,  onBeforeUnmount, onUpdated } from "vue";
import { useStore } from "vuex";
import shopSchema from "../../schemas/shop";
import shopConstants from "../../constants/shopConstants"
import trophyConstants from "../../constants/trophyConstants"
import { notify } from '@kyvg/vue3-notification';
import dispatchMap from "@/constants/dispatchMap";
import { io } from "socket.io-client";
import ConfirmPopup from '../../components/common/ConfirmPopup.vue'
import _ from "lodash";

export default {
  name: "DailyDeals",
  components: {
    ConfirmPopup
  },
  setup() {
    const store = useStore();
    const shopObj = ref({ ...shopSchema })
    const isSectionOpen = ref([])
    const usedPages = ["shop"];
    let submitErrors = [];
    const socket = io(process.env.VUE_APP_BACKEND_URL);
    const popupOpen = ref(false);
    const popupTexts = ["This page is edited by another user. Please refresh before making changes.", "Your changes: "];
    const popupText = ref(popupTexts[0])
    const popupType = ref("refresh");
    const changes = ref([]);
    window.firstDailyDeals = null;

    onBeforeMount(() => usedPages.forEach(page => {
      dispatchMap[page].forEach(dispatchStr => store.dispatch(dispatchStr));
    }));
    watch(() => store.getters['shop/getShopContent'], value => {            
        if (value) {
            shopObj.value = JSON.parse(JSON.stringify(value))
            isSectionOpen.value = new Array(shopObj.value.content.length).fill(false)
            window.firstDailyDeals = JSON.parse(JSON.stringify(value));
        }
    }, { immediate: true })

    const units = computed(() => store.getters["units/getUnits"]);
    const chests = computed(() => store.getters['chests/getChests'])
    const heroes = computed(() => store.getters["heroes/getHeroes"]);
    const submit = () => {
        let isValid = checkDealsValid(submitErrors);
        if (!isValid){
          notify({
                    title: "Validation Error",
                    text: submitErrors.join(""),
                    type: 'error'
                })
          submitErrors = [];
        }
        for(let i = 0; i < shopObj.value.content.length; i++) {
            if (Object.keys(shopObj.value.content[i]).length === 0) {
                notify({
                    title: "Validation Error",
                    text: `Arena ${i + 1} has no pool`,
                    type: 'error'
                })
                isValid = false
            }
        }

        if (isValid) {
              store.dispatch('shop/updateShopContent', shopObj.value).then(() => {
              socket.emit("update", {
                socketId: socket.id,
                itemId: "Daily Deals",
                page: "Daily Deals",
                data: shopObj.value.content
              });
            });
            popupOpen.value = false;
            changes.value = [];
        }
    }
    onUpdated(() => store.dispatch("loader/loadingStatus", false));
    onBeforeUnmount(() => store.dispatch("loader/loadingStatus", true));
    
    const exportTo = environment => {
        store.dispatch('shop/exportShopContent', { shopContent: shopObj.value.content, environment })
    }

    const toggleSection = (sectionIndex) => {
        isSectionOpen.value[sectionIndex] = !isSectionOpen.value[sectionIndex]
    }

    const initializeRewardType = (reward) => {
        if ([0,1,4].includes(reward.type)) {
            if (reward.rarity !== undefined)
                delete reward.rarity;
        } else {
            if (!reward.rarity)
                reward.rarity = 0
            if(reward.chest_id)
              delete reward.chest_id;
        }
    }

    const onCurrencyChange = (currency, priceObject) => {
        priceObject[currency] = 0
        delete priceObject[currency == 'gold' ? 'gem' : 'gold']
    }

    const addPool = arenaIndex => {
        const poolNumber = Object.keys(shopObj.value.content[arenaIndex]).length + 1;
        shopObj.value.content[arenaIndex][poolNumber] = JSON.parse(JSON.stringify(shopConstants.NEW_POOL));
    }

    const removePool = (arenaIndex, poolKey) => {
        const greaterKeys = Object.keys(shopObj.value.content[arenaIndex]).map(x => Number.parseInt(x))
            .filter(x => x > Number.parseInt(poolKey))
            .sort((a, b) => a - b)

        delete shopObj.value.content[arenaIndex][poolKey]

        for (let i = 0; i < greaterKeys.length; i++) {
            shopObj.value.content[arenaIndex]['' + (greaterKeys[i] - 1)] = shopObj.value.content[arenaIndex]['' + (greaterKeys[i])]
        }

        if (greaterKeys.length !== 0)
            delete shopObj.value.content[arenaIndex]['' + greaterKeys[greaterKeys.length-1]]
    }

    const checkDealsValid = () => {
        let valid = true;
        let res;
        for(let index = 0; index < shopObj.value.content.length; index++){
          res = checkArenaDealsValid(shopObj.value.content[index], index)
          if (!res){
            valid = false;
          }
        }
        return valid;
    }

    const checkArenaDealsValid = (arena, arenaIndex) => {
      let arenaValid = true;
      for(let poolIndex = 1; poolIndex <= Object.keys(arena).length; poolIndex++) {
        Object.keys(arena[poolIndex]).forEach(offer => {
          if (arena[poolIndex][offer].reward.type == 2){
            let rarityUnits = units.value.filter(unit => unit.rarity == arena[poolIndex][offer].reward.rarity
              && unit.trophy_level <= trophyConstants.ARENA_TYPES[arenaIndex].minTrophy);
            if (!rarityUnits.length){
              submitErrors.push("Unit error on Arena" + (arenaIndex+1) + ", Pool " + (poolIndex-1) + "<br>");
              arenaValid = false;
            }
          }
          if (arena[poolIndex][offer].reward.type == 3){
            let rarityHeroes = heroes.value.filter(hero => hero.rarity == arena[poolIndex][offer].reward.rarity
              && hero.trophy_level <= trophyConstants.ARENA_TYPES[arenaIndex].minTrophy);
            if (!rarityHeroes.length){
              submitErrors.push("Hero error on Arena" + (arenaIndex+1) + ", Pool " + (poolIndex-1) + "<br>");
              arenaValid = false;
            }
          }
        })
      }
      return arenaValid;
    }
    socket.on('updated', (data) => {
      if (data.socketId != socket.id && data.page === "Daily Deals" && data.data){
        popupOpen.value = true;
        popupType.value = "refresh";
        popupText.value = popupTexts[0];
        detectChanges(shopObj.value.content, data.data)
      }
    })

    const detectChanges = (current, incoming) => {
      for (let i=0; i<current.length;i++){
          if(Object.keys(current[i]).length != Object.keys(incoming[i]).length)
            changes.value.push("Pools are updated on Arena " + (i+1))
          for (let j=1; j<=Object.keys(current[i]).length; j++){
            for (let k=0; k<current[i][j].length;k++){
              if(!(_.isEqual(incoming[i][j][k], current[i][j][k])))
                changes.value.push("Reward " + (k+1) + " on Pool " + (j-1) + ", Arena " + (i+1))
            }
          }
        }

    }

    const openConfirm = () => {
      popupType.value = "confirm";
      popupOpen.value = true;
      popupText.value = popupTexts[1];
      detectChanges(window.firstDailyDeals.content, shopObj.value.content);
    }

    const appConfig = computed(() => store.getters['auth/getAppConfig'])
    const env = appConfig.value.appEnv || 'dev'

    return {
      shopObj,
      submit,
      exportTo,
      env,
      isSectionOpen,
      toggleSection,
      shopConstants,
      initializeRewardType,
      onCurrencyChange,
      addPool,
      removePool,
      popupOpen,
      popupText,
      popupType,
      changes,
      openConfirm,
      chests
    }
  }
}
</script>

<style scoped>
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 30px;
}

.main-heading {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.col {
  padding: 15px;
}

.col-header {
  font-size: 1.25rem;
  font-weight: bold;
}

.content-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 25px;
}
.content-row {
  padding: 15px;
  width: calc(100% - 30px);
  display: grid;
  grid-template-columns: 3fr 3fr 3fr 2fr;
  grid-column-gap: 10px;
  align-items: center;
}

.content-row.header-row {
  font-weight: bold;
}

.content-row.alternate {
  background-color: lightgray;
}

.row-title {
  width: 80%;
  text-align: start;
}

button {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: 1px solid gray;
  padding: 5px 20px;
  margin: 5px 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

button.error {
  background-color: rgba(255, 0, 0, 0.15);
}

button.success {
  background-color: rgba(0, 255, 0, 0.15);
}

button.info {
  background-color: rgba(0, 0, 255, 0.15);
}

.buttons-section {
  display: flex;
  padding: 30px 0;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  box-sizing: border-box;
}

.buttons-section button {
  font-size: 1.25rem;
  margin: 5px 0;
  color: black
}

.col-full-width {
  width: 100%;
  box-sizing: border-box;
}
.table {
  width: 100%;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.arena-item {
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    border: 2px solid gray;
    border-radius: 10px;
    height: 50px;
    width: 80%;
    font-size: 20px;
    cursor: pointer;
}

.arena-content {
    margin: 20px;
    width: 80%;
}

.arena-item:hover {
    background-color: lavender;
}

.row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 10px;
  margin: 10px 0;
}

.row.header-row {
  background-color: #cccccc;
  font-weight: bold;
}

</style>